<template>
  <div class="text-center">
    Saliendo, porfavor espere... <br />
    <v-progress-circular
      indeterminate
      x-large
      color="primary"
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "LogoutPage",
  created() {
    this.logout();
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    async logout() {
      if (this.isLoggedIn) {
        await this.$store.dispatch("logout");
      }
      this.toLogin();
    },
    toLogin() {
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style></style>
